import React from "react";
import { FormatNumber } from "../../helpers";
import { useCashTotal } from "../../api";
import Skeleton from "@yisheng90/react-loading";

export default (props) => {
  const { totalCash, loading } = useCashTotal();

  return (
    <div className="text-left">
      <small>Cash</small>
      <span className="d-block font-weight-bold">
        {loading ? <Skeleton width={100} /> : `Rp ${FormatNumber(totalCash)}`}
      </span>
    </div>
  );
};
