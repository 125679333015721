import React from "react";
import NavTop from "../../component/NavTop";
import { FaCrown, FaLongArrowAltRight } from "react-icons/fa";
import { IoIosContact, IoIosArrowForward } from "react-icons/io";
import Pricing from "./Pricing";
import { FormatDate, FormatNumber } from "../../helpers";
import { useConfig, subscribe } from "../../api/companyApi";
import { toast } from "react-toastify";

const Upgrade = (props) => {
  const { data: config, error } = useConfig();
  const format = "D MMM YYYY";
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const lastDay = FormatDate(new Date(year, month + 1, 0), format);
  const firstCredit = FormatDate(new Date(year, month + 2, 1), format);
  const dt = new Date(new Date().setMonth(new Date().getMonth() + 1));
  const usageDate = FormatDate(dt, "MMMM YYYY");

  const price = FormatNumber(10000);
  const upgradeClick = () => {
    subscribe().then((r) => {
      toast.success("Upgrade Account berhasil!");
      props.history.push("/account");
    });
  };

  return (
    <>
      <NavTop title="Upgrade Account" />
      <div className="main-padding">
        <div className="text-center">
          <IoIosContact size="3em" className="text-secondary" />
          <FaLongArrowAltRight size="2em" className="mx-3" />
          <FaCrown size="3em" className="text-warning" />
        </div>
        <Pricing config={config} />
        <div className="z-card mb-3">
          <p>
            Kami menggunakan sistem <strong>credit</strong> untuk mekanisme
            pembayaran. Silahkan Anda melakukan Top Up untuk memastikan Credit
            Anda cukup. Setiap tanggal 1 sistem kami akan melakukan pemotongan
            credit sebagai biaya berlangganan akun premium.
          </p>
          <p>
            Jika Anda melakukan upgrade Akun hari ini, Anda berhak mendapatkan
            layanan premium secara gratis hingga tanggal{" "}
            <strong>{lastDay}</strong>
          </p>
          <table className="table">
            <tbody>
              <tr>
                <th>Free Trial</th>
                <td>
                  sampai <strong>{lastDay}</strong>
                </td>
              </tr>
              <tr>
                <th>Tagihan Pertama</th>
                <td>
                  <div>
                    <strong>{firstCredit}</strong>
                    <small className="d-block">penggunaan {usageDate}</small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button className="btn btn-block btn-warning" onClick={upgradeClick}>
          <FaCrown /> Upgrade Account
        </button>
      </div>
    </>
  );
};

export default Upgrade;
