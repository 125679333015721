import React from "react";
import Nav from "../../component/Nav";
import { IoIosPulse, IoMdAdd, IoIosArrowForward } from "react-icons/io";
import Fab from "../../component/FAB";
import Empty from "../../component/Empty";
import { useRealCash, useCashTotal } from "../../api";
import { FormatNumber } from "../../helpers";
import CashLoader from "../../loader/CashLoader";

export default (props) => {
  const { totalCash, loading } = useCashTotal();
  const { cash, isLoading } = useRealCash();

  if (loading || isLoading) {
    return (
      <>
        <Nav title="Cash" icon={IoIosPulse} />
        <CashLoader />
      </>
    );
  }

  if (cash?.length === 0) {
    return (
      <>
        <Nav title="Cash" icon={IoIosPulse} />
        <Empty className="mt-5" />
        <Fab icon={IoMdAdd} onClick={(e) => props.history.push("/cash/add")} />
      </>
    );
  }

  return (
    <div>
      <Nav title="Cash" icon={IoIosPulse} />
      <div className="main-padding">
        <small className="d-block m-auto text-center">Total Cash</small>
        <div className="text-center text-big">
          <small>Rp</small> {FormatNumber(totalCash)}
        </div>
        <hr />
        {cash.map((d, i) => (
          <div
            key={i}
            className="z-card mb-2 d-flex justify-content-between align-items-center cursor-pointer"
            onClick={() =>
              props.history.push(`/cash/edit/${d.id}/${d.name}/${d.amount}`)
            }
          >
            <div>
              <span className="d-block mb-n1 font-weight-bold">
                Rp {FormatNumber(d.amount)}
              </span>
              <small className="text-secondary">{d.name}</small>
            </div>
            <IoIosArrowForward />
          </div>
        ))}
      </div>
      <Fab icon={IoMdAdd} onClick={(e) => props.history.push("/cash/add")} />
    </div>
  );
};
