import React from "react";
import { FormatNumber } from "../../helpers";
import { useCashflowTotal } from "../../api";
import Skeleton from "@yisheng90/react-loading";

export default (props) => {
  const { totalCashflow, loading } = useCashflowTotal();

  return (
    <div className="text-right">
      <small>Transaction</small>
      <span className="d-block font-weight-bold">
        {loading ? (
          <Skeleton width={100} />
        ) : (
          `Rp ${FormatNumber(totalCashflow)}`
        )}
      </span>
    </div>
  );
};
