import React from "react";
import { IoIosPulse } from "react-icons/io";
import { FormatNumber } from "../../helpers";

const Pricing = (props) => {
  return (
    <div className="d-flex my-3">
      <div className="z-card" style={{ width: "50%", marginRight: "5px" }}>
        <p className="text-center">
          <strong>Gratis</strong>
        </p>
        <hr />
        <ul style={{ marginLeft: "9px" }}>
          <li>Unlimited catatan keuangan (transaksi)</li>
          <li>{props?.config?.walletLimit} Wallet</li>
          <li>{props?.config?.realCashLimit} Real Cash</li>
        </ul>
        {props.action && (
          <div>
            <hr />
            <button className="btn btn-sm btn-outline-success">
              <IoIosPulse /> daftar
            </button>
          </div>
        )}
      </div>
      <div className="z-card" style={{ width: "50%", marginLeft: "5px" }}>
        <p className="text-center">
          <strong>
            {FormatNumber(props?.config?.subscriptionPrice)} /{" "}
            <small>bulan</small>
          </strong>
        </p>
        <hr />
        <ul style={{ marginLeft: "9px" }}>
          <li>Unlimited catatan keuangan (transaksi)</li>
          <li>Unlimited Wallet</li>
          <li>Unlimited Real Cash</li>
        </ul>
        {props.action && (
          <div>
            <hr />
            <button className="btn btn-sm btn-outline-success">
              <IoIosPulse /> daftar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pricing;
