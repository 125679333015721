import React from "react";
import { IoIosWallet } from "react-icons/io";
import NavTop from "../../component/NavTop";
import WalletList from "./WalletList";

export default (props) => {
  return (
    <>
      <NavTop title="Wallet" icon={IoIosWallet} />
      <WalletList type="select" {...props} />
    </>
  );
};
