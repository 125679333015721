import React from "react";
import { useTopUp } from "../../api";
import NavTop from "../../component/NavTop";
import InvoiceItem from "./InvoiceItem";
import Empty from "../../component/Empty";
import InvoiceLoader from "../../loader/InvoiceLoader";

const Invoices = (props) => {
  const { topup, isLoading } = useTopUp();

  if (isLoading) return <div />;

  if (topup.length === 0)
    return (
      <div>
        <NavTop title="Invoices" />
        <Empty />
      </div>
    );
  return (
    <>
      <NavTop title="Invoices" />
      <div className="main-padding">
        {topup.map((inv) => (
          <InvoiceItem key={inv.id} data={inv} />
        ))}
      </div>
    </>
  );
};

export default Invoices;
