import React from "react";
import { useCreditHistories } from "../../api/creditApi";
import NavTop from "../../component/NavTop";
import Empty from "../../component/Empty";
import CreditItem from "./CreditItem";

const CreditHistories = (props) => {
  const { credit, isLoading } = useCreditHistories();
  if (isLoading) return <div />;
  if (credit.length === 0) {
    return (
      <>
        <NavTop title="Credits" />
        <Empty />
      </>
    );
  }
  return (
    <div>
      <NavTop title="Credits" />
      <div className="main-padding">
        {credit.map((h) => (
          <CreditItem key={h.id} data={h} />
        ))}
      </div>
    </div>
  );
};

export default CreditHistories;
