import axiosInstance from "./axiosInstance";
import useSWR from "swr";

export const useWallet = () => {
  const { data, error } = useSWR("/wallet");
  return {
    wallets: data || [],
    isLoading: data === undefined,
  };
};

export async function walletPost(data) {
  return await axiosInstance.post("/wallet", data);
}

export async function walletUpdate(data) {
  return await axiosInstance.put("/wallet", data);
}

export async function walletDelete(id) {
  return await axiosInstance.delete("/wallet/" + id);
}

export const useWalletAmount = () => {
  const { data } = useSWR("/wallet/total-amount");
  return {
    loading: data === undefined,
    walletAmounts: data || [{}],
  };
};

export const useWalletBalances = (walletId) => {
  const { data } = useSWR("/wallet/balance/" + walletId);
  return {
    isLoading: data === undefined,
    balance: data || [],
  };
};
