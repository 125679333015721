import React from "react";
import { IoIosAnalytics, IoIosArrowForward } from "react-icons/io";
import { AiOutlineLineChart, AiOutlineBorderlessTable } from "react-icons/ai";
import { FormatNumber } from "../../helpers";
import Skeleton from "@yisheng90/react-loading";
import LineChart from "./LineChart";

export default (props) => {
  const [isTable, setIsTable] = React.useState(true);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Ags",
    "Sept",
    "Okt",
    "Nov",
    "Des",
  ];
  const { wallet, loading } = props;
  let data = props.data.map((d) => {
    const m = (d.year + d.month.toString().padStart(2, "0")) * 1;
    return { m, ...d };
  });
  return (
    <div className="z-card">
      <div>
        <IoIosAnalytics className="mr-1" />
        <span className="font-weight-bold">Transaksi per Bulan</span>
      </div>
      <hr className="mt-1" />
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <AiOutlineBorderlessTable
            size={isTable ? "1.3em" : "1em"}
            className={`mr-2 cursor-pointer ${isTable ? "" : "text-secondary"}`}
            onClick={() => setIsTable(true)}
          />
          <AiOutlineLineChart
            size={!isTable ? "1.3em" : "1em"}
            className={`${!isTable ? "" : "text-secondary"} cursor-pointer`}
            onClick={() => setIsTable(false)}
          />
        </div>
        <button className="btn btn-sm" onClick={props.onSelectWallet}>
          {wallet?.name} <IoIosArrowForward />
        </button>
      </div>
      {isTable ? (
        <table className="table table-sm z-table text-right">
          <thead>
            <tr>
              <th>Bulan</th>
              <th>In</th>
              <th>Out</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {loading
              ? [...Array(5).keys()].map((i) => (
                  <tr key={i}>
                    <td colSpan="4">
                      <Skeleton width="100%" />
                    </td>
                  </tr>
                ))
              : data.map((d, i) => (
                  <tr
                    key={i}
                    className={d.positive + d.negative < 0 ? "text-danger" : ""}
                  >
                    <td>{`${d.year}-${months[d.month - 1]}`}</td>
                    <td>{FormatNumber(d.positive || 0)}</td>
                    <td>{FormatNumber(d.negative || 0)}</td>
                    <td>{FormatNumber(d.positive + d.negative)}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      ) : (
        <LineChart trx={data} months={months} />
      )}
    </div>
  );
};
