import axiosInstance from "./axiosInstance";
import useSWR from "swr";

export function useRealCash() {
  const { data, error } = useSWR("/cash");
  return {
    cash: data,
    isLoading: data === undefined,
  };
}

export async function realCashPost(data) {
  return await axiosInstance.post("/cash", data);
}

export async function realCashUpdate(data) {
  return await axiosInstance.put("/cash", data);
}

export async function realCashDelete(id) {
  return await axiosInstance.delete("/cash/" + id);
}

export const useCashTotal = () => {
  const { data } = useSWR("/cash/total");
  return {
    totalCash: data ? data.sum || 0 : 0,
    loading: data === undefined,
  };
};
