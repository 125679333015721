import React from "react";
import BankItem from "./BankItem";

const BankCard = (props) => {
  return (
    <div className="z-card">
      <BankItem />
    </div>
  );
};

export default BankCard;
