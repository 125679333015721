import React from "react";
import Skeleton from "@yisheng90/react-loading";

export default (props) => {
  const dummies = [...Array(10).keys()];
  return (
    <div className="main-padding">
      <Skeleton width={70} height={10} className="mx-auto" />
      <Skeleton width={120} height={15} className="mx-auto" />
      <hr />
      {dummies.map((d, i) => (
        <div key={i} className="z-card mb-1 ">
          <Skeleton width={80} height={10} />
          <Skeleton width={120} height={15} />
        </div>
      ))}
    </div>
  );
};
