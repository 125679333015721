import {
  IoIosHome,
  IoIosCart,
  IoIosWallet,
  IoIosPulse,
  IoIosContact,
} from "react-icons/io";
import Home from "./pages/home/Home";
import Transaction from "./pages/transaction/transaction";
import TransactionAdd from "./pages/transaction/transactionAdd";
import TransactionEdit from "./pages/transaction/transactionEdit";
import Account from "./pages/account/account";
import Wallet from "./pages/wallet/Wallet";
import Cash from "./pages/cash/cash";
import CashAdd from "./pages/cash/CashAdd";
import CashEdit from "./pages/cash/CashEdit";
import WalletAdd from "./pages/wallet/walletAdd";
import WalletEdit from "./pages/wallet/walletEdit";
import WalletSearch from "./pages/wallet/WalletSearch";
import Forbidden from "./pages/others/Forbidden";
import ChangePassword from "./pages/account/ChangePassword";
import EditProfile from "./pages/account/EditProfile";
import TransactionSearch from "./pages/transaction/TransactionSearch";
import CreditHistories from "./pages/credit/CreditHistories";
import TopUp from "./pages/topup/TopUp";
import TopUpInstruction from "./pages/topup/TopUpInstruction";
import Invoices from "./pages/topup/Invoices";
import Users from "./pages/users/Users";
import UpgradeAccount from "./pages/account/Upgrade";
import About from "./pages/account/About";

export default [
  { to: "/forbidden", public: true, component: Forbidden },
  {
    to: "/",
    title: "Home",
    icon: IoIosHome,
    layer: 1,
    exact: true,
    component: Home,
  },
  {
    to: "/transaction",
    title: "Transaction",
    exact: true,
    icon: IoIosCart,
    layer: 1,
    component: Transaction,
  },
  {
    to: "/transaction-search",
    title: "Transaction Search",
    exact: true,
    component: TransactionSearch,
  },
  {
    to: "/transaction/add",
    component: TransactionAdd,
    exact: true,
  },
  {
    to: "/transaction/edit/:id",
    component: TransactionEdit,
  },
  {
    to: "/wallet",
    title: "Wallet",
    icon: IoIosWallet,
    exact: true,
    layer: 1,
    component: Wallet,
  },
  {
    to: "/wallet/add",
    component: WalletAdd,
  },
  {
    to: "/wallet/edit",
    component: WalletEdit,
  },
  {
    to: "/wallet/search/:current/:key/:all",
    component: WalletSearch,
  },
  {
    to: "/cash",
    title: "Cash",
    exact: true,
    icon: IoIosPulse,
    layer: 1,
    component: Cash,
  },
  {
    to: "/cash/add",
    component: CashAdd,
  },
  {
    to: "/cash/edit/:id/:name/:amount",
    component: CashEdit,
  },
  {
    to: "/account",
    title: "Account",
    icon: IoIosContact,
    layer: 1,
    exact: true,
    component: Account,
  },
  {
    to: "/account/edit-profile",
    component: EditProfile,
  },
  {
    to: "/account/change-password",
    component: ChangePassword,
  },
  {
    to: "/credits",
    component: CreditHistories,
  },
  {
    to: "/topup",
    component: TopUp,
    exact: true,
  },
  {
    to: "/topup/instruction/:amount/:expire",
    component: TopUpInstruction,
  },
  {
    to: "/invoices",
    component: Invoices,
  },
  {
    to: "/users",
    component: Users,
  },
  {
    to: "/upgrade-account",
    component: UpgradeAccount,
  },
  {
    to: "/about",
    component: About,
  },
];
