import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../constant";
import { Auth } from "aws-amplify";

const requestHandlerX = async (request) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    if (request.url.indexOf("http") < 0)
      request.headers["Authorization"] = token;
  } catch {
  } finally {
    return request;
  }
};

const getSession = (user, refreshToken) => {
  const promise = new Promise((resolve, reject) => {
    user.refreshSession(refreshToken, (err, data) => {
      if (err) reject(err);
      else resolve(data);
    });
  });
  return promise;
};

const requestHandler = async (request) => {
  try {
    const session = await Auth.currentSession();
    var idTokenExpire = session.getIdToken().getExpiration() * 1000;
    var refreshToken = session.getRefreshToken();
    var currentTimeSeconds = new Date().getTime();
    if (idTokenExpire < currentTimeSeconds) {
      const user = await Auth.currentAuthenticatedUser();
      const newSession = await getSession(user, refreshToken);
      request.headers["Authorization"] = newSession.getIdToken().getJwtToken();
    } else
      request.headers["Authorization"] = session.getIdToken().getJwtToken();
  } catch (err) {
    Auth.signOut();
  } finally {
    return request;
  }
};

const errorHandler = (error) => {
  if (error.message == "Cannot retrieve a new session. Please authenticate.") {
    localStorage.clear();
  } else if (error.response && error.response.data)
    toast.error(error.response.data.message);
  else toast.error(error.message);
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};
// Init Axios
const axiosInstance = axios.create({
  baseURL: API,
});

// Add interceptors
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
