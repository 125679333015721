import React from "react";
import { getCompanies } from "../../api";
import NavTop from "../../component/NavTop";
import UserItem from "./UserItem";

const Users = (props) => {
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    getCompanies().then((r) => setUsers(r.data.content));
  }, []);
  return (
    <>
      <NavTop title="Users" />
      <div className="main-padding">
        {users.map((u) => (
          <UserItem key={u.id} data={u} />
        ))}
      </div>
    </>
  );
};

export default Users;
