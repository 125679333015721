import React from "react";
import MandiriIcon from "../../assets/img/mandiri.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiOutlineCopy } from "react-icons/ai";

const rekening = "1320012219433";
const BankItem = (props) => {
  const [copied, setCopied] = React.useState(false);
  const copying = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className="d-flex align-items-center mb-4">
      <img src={MandiriIcon} width="50px" />
      <div className="ml-3">
        <strong className="d-block">Bank mandiri</strong>
        <span className="d-block">
          {rekening}
          <CopyToClipboard text={rekening} onCopy={copying}>
            <AiOutlineCopy />
          </CopyToClipboard>
          {copied ? <small className="text-secondary">copied!</small> : ""}
        </span>
        <span className="d-block">a.n. Windu Purnomo</span>
      </div>
    </div>
  );
};

export default BankItem;
