import React from "react";
import Skeleton from "@yisheng90/react-loading";

export default (props) => {
  const dummies = [...Array(10).keys()];
  return (
    <div className="main-padding">
      {dummies.map((d, i) => (
        <div key={i} className="z-card mb-1 ">
          <Skeleton width={100} height={15} /> 
          <Skeleton width={150} height={10} /> 
        </div>
      ))}
    </div>
  );
};
