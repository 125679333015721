import React from "react";
import { IoIosAdd, IoIosWallet } from "react-icons/io";
import { Link } from "react-router-dom";
import { FormatNumber } from "../../helpers";
import Skeleton from "@yisheng90/react-loading";

export default (props) => {
  const { data, loading } = props;
  const dummies = [...Array(2).keys()];
  const addWallet = () => {
    props.history.push("/wallet/add");
  };
  if (loading) {
    return (
      <>
        {dummies.map((d, i) => (
          <div key={i} className="text-center">
            <Skeleton width={80} className="mx-auto mb-1" height={10} />
            <Skeleton width={150} className="mx-auto" height={15} />
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      {data.map((wa, i) => {
        wa.sum = wa.sum || 0;
        const color = wa.sum < 0 ? "text-danger" : "";
        if (wa.sum === 0) return null;
        if (wa.additional) {
          return (
            <Link
              key={i}
              to="/wallet/add"
              className="wallet-value cursor-pointer"
            >
              <small className="d-block">Tambah Wallet</small>
              <IoIosWallet />
            </Link>
          );
        }
        return (
          <div key={i} className={`wallet-value ${color}`}>
            <small>{wa.name}</small>
            <span>Rp {FormatNumber(wa.sum)}</span>
          </div>
        );
      })}
    </>
  );
};
