import React from "react";
import { Line } from "react-chartjs-2";
import { FormatNumber } from "../../helpers";

const LineChart = (props) => {
  const { months, trx } = props;
  const positive = trx.map(d => d.positive);
  const negative = trx.map(d => Math.abs(d.negative)).filter(d => d !== 0)
  const max = Math.max(...positive.concat(negative));

  const data = {
    labels: trx.map(d => months[d.month - 1] + " " + d.year),
    datasets: [
      {
        label: "Pemasukan",
        data: trx.map(d => d.positive),
        borderColor: ["rgba(0, 0, 255, 0.5)"],
        backgroundColor: ["rgba(0, 0, 255, 0.25)"]
      },
      {
        label: "Pengeluaran",
        data: trx.map(d => Math.abs(d.negative)),
        borderColor: ["rgba(255, 0, 0, 0.5)"],
        backgroundColor: ["rgba(255, 0, 0, 0.25)"]
      }
    ]
  }

  const options = {
    title: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: max * (120 / 100),
            size: 10,
            callback(value) {
              return FormatNumber(value);
            }
          }
        },
      ]
    }
  }

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  )
}

export default LineChart
