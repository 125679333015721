import React from "react";
import { Link } from "react-router-dom";
import Nav from "../../component/Nav";
import { FaCrown } from "react-icons/fa";
import { IoIosContact, IoIosArrowForward } from "react-icons/io";
import { GrEdit } from "react-icons/gr";
import { AuthContext } from "../../auth/ZAuth";
import { FormatNumber } from "../../helpers";
import { useCompany } from "../../api";
import Skeleton from "@yisheng90/react-loading";
import AccountLoader from "../../loader/AccountLoader";

export default (props) => {
  const { data: company, error } = useCompany();
  const { user, logout } = React.useContext(AuthContext);
  const [loadingApi, setLoadingApi] = React.useState(false);
  if (!company) {
    return (
      <>
        <Nav title="Account" icon={IoIosContact} />
        <AccountLoader />
      </>
    )
  }

  return (
    <>
      <Nav title="Account" icon={IoIosContact} />
      <div className="main-padding">
        <div className="d-flex align-items-center justify-content-between z-card mb-2">
          <div className="d-flex align-items-center">
            {company.level === 0 ? (
              <IoIosContact size="3em" color="gray" />
            ) : (
              <FaCrown size="2.5em" className="text-warning" />
            )}
            <div className="ml-4">
              <span className="d-block font-weight-bold">{user.name || user.username}</span>
              <span className="d-block">{user.email}</span>
              {company.level === 0 ? (
                <span className="badge badge-secondary">free account</span>
              ) : (
                <span className="badge badge-warning">premium account</span>
              )}
            </div>
          </div>
          <GrEdit
            size="1.3em"
            className="mr-3"
            onClick={(e) => props.history.push("/account/edit-profile")}
          />
        </div>
        <div className="z-card align-items-center mb-2 text-center">
          <small className="d-block">Credit Anda</small>
          {
            <span
              className={`text-big ${
                company.credit < 0 ? "text-danger" : ""
              }  `}
            >
              {FormatNumber(company.credit)}
            </span>
          }
        </div>
        <div className="list-menu mt-3">
          {user.username === "windupurnomo" && (
            <div onClick={(e) => props.history.push("/users")}>
              <span>Users</span>
              <IoIosArrowForward />
            </div>
          )}
          {company.level === 0 && (
            <div onClick={(e) => props.history.push("/upgrade-account")}>
              <span>Upgrade Account</span>
              <IoIosArrowForward />
            </div>
          )}
          <div onClick={(e) => props.history.push("/topup")}>
            <span>Top Up</span>
            <IoIosArrowForward />
          </div>
          <div onClick={(e) => props.history.push("/invoices")}>
            <span>Invoice</span>
            <IoIosArrowForward />
          </div>
          <div onClick={(e) => props.history.push("/credits")}>
            <span>Credit History</span>
            <IoIosArrowForward />
          </div>
          <div onClick={(e) => props.history.push("/account/change-password")}>
            <span to="/account/change-password">Ubah Password</span>
            <IoIosArrowForward />
          </div>
          <div onClick={(e) => props.history.push("/about")}>
            <span to="/about">Tentang Cashflow</span>
            <IoIosArrowForward />
          </div>
          <div
            onClick={(e) =>
              window.open("https://wa.me/6285776556212", "_blank")
            }
          >
            <span>Kontak Developer</span>
            <IoIosArrowForward />
          </div>
          <div onClick={logout}>
            <span className="text-danger">Keluar</span>
            <IoIosArrowForward />
          </div>
        </div>
      </div>
    </>
  );
};
