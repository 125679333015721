import React from "react";
import {
  IoMdAdd,
  IoIosArrowForward,
  IoIosApps,
  IoMdBulb,
} from "react-icons/io";
import Nav from "../../component/Nav";
import { useWalletAmount, initCompany, useWalletBalances } from "../../api";
import Fab from "../../component/FAB";
import SummaryMonth from "./SummaryMonth";
import TotalTransaction from "./TotalTransaction";
import TotalCash from "./TotalCash";
import TransactionWallet from "./TransactionWallet";

const Home = (props) => {
  const { walletAmounts, loading } = useWalletAmount();
  const walletHome = JSON.parse(localStorage.getItem("walletHome"));
  const wallet = walletHome || walletAmounts[0];
  const { balance, isLoading } = useWalletBalances(wallet?.id);

  React.useEffect(() => {
    const hasInitCompany = localStorage.getItem("hasInitCompany");
    if (!hasInitCompany) {
      initCompany();
      localStorage.setItem("hasInitCompany", "true");
    }
  }, []);

  if (window.location.href.indexOf("id_token") >= 0) return <div />;

  const selectWallet = (e) => {
    const url = `/wallet/search/${wallet.id}/walletHome/notall`;
    props.history.push(url);
  };

  return (
    <>
      <Nav icon={IoMdBulb} />
      <div className="main-padding mb-5">
        <div className="z-card d-flex justify-content-center mb-2">
          <TotalTransaction />
          <div className="v1" />
          <TotalCash />
        </div>

        <div className="z-card mb-2">
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={(e) => props.history.push("/transaction")}
          >
            <div>
              <IoIosApps className="mr-1" />
              <span className="font-weight-bold">Saldo Tiap Wallet</span>
            </div>
            <IoIosArrowForward />
          </div>
          <hr className="mt-1" />
          <div className="column-2">
            <TransactionWallet loading={loading} data={walletAmounts} />
          </div>
        </div>
        <SummaryMonth
          wallet={wallet}
          data={balance}
          onSelectWallet={selectWallet}
          loading={loading || isLoading}
        />
      </div>
      <Fab
        icon={IoMdAdd}
        onClick={(e) => {
          localStorage.removeItem("walletSearch");
          props.history.push("/transaction/add");
        }}
      />
    </>
  );
};

export default Home;
