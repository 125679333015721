import axiosInstance from "./axiosInstance";
import useSWR from "swr";

export async function cashflowGet(date, size, walletId, tagNames) {
  size = size || 5;
  walletId = walletId === "0" ? undefined : walletId;
  let url = `/transaction?limit=${size}`;
  if (date) url += "&date=" + date;
  if (walletId) url += "&walletId=" + walletId;
  if (tagNames && tagNames.length > 0) url += "&tags=" + tagNames.join(",");
  return await axiosInstance.get(url);
}

export async function cashflowPost(data) {
  return await axiosInstance.post("/transaction", data);
}

export async function cashflowUpdate(data) {
  return await axiosInstance.put("/transaction/" + data.id, data);
}

export async function cashflowDelete(id) {
  return await axiosInstance.delete("/transaction/" + id);
}

export const useCashflowTotal = () => {
  const { data } = useSWR("/transaction/total");
  return {
    totalCashflow: data ? data.sum || 0 : 0,
    loading: data === undefined,
  };
};
