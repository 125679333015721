import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FormatDate, FormatNumber } from "../../helpers";
import Modal from "../../component/Modal";
import NumberFormat from "react-number-format";
import { topUp } from "../../api";

const UserItem = ({ data }) => {
  const {
    id,
    name,
    email,
    level,
    credit: creditInit,
    register_date: registerDate,
  } = data;
  const [modal, setModal] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(null);
  const [topupAmount, setTopupAmount] = React.useState(0);
  const [credit, setCredit] = React.useState(creditInit);
  const creditClass = `d-block text-big ${credit < 0 ? "text-danger" : ""}`;
  const submitTopUp = async () => {
    if (topupAmount === 0) {
      setErrorModal("Nilai top up tidak boleh nol");
      return;
    }
    try {
      await topUp(topupAmount, id);
      setCredit(credit + topupAmount);
    } catch (err) {
    } finally {
      setModal(false);
    }
  };
  return (
    <>
      <div className="z-card mb-2">
        <div className="d-flex justify-content-between">
          <div>
            <strong>{name}</strong>
            <span className="d-block">{email}</span>
            {level === 0 ? (
              <span className="badge badge-secondary">free account</span>
            ) : (
              <span className="badge badge-warning">premium account</span>
            )}
          </div>
          <div className="text-right">
            <AiOutlineClockCircle /> {FormatDate(registerDate)}
            <span className={creditClass}>{FormatNumber(credit)}</span>
          </div>
        </div>
        <hr />
        <div className="text-right">
          <span
            className="text-danger cursor-pointer"
            onClick={(e) => setModal(true)}
          >
            Top Up
          </span>
        </div>
      </div>
      <Modal
        show={modal}
        title="Top Up Credit"
        onClose={() => setModal(false)}
        onPositive={submitTopUp}
        textPositive="Submit"
        textNegative="Cancel"
      >
        Nominal credit yang akan diberikan ke&nbsp;
        <strong className="text-danger">{name}</strong>
        <NumberFormat
          id="amount"
          className="form-control mt-1"
          thousandSeparator={true}
          prefix={"Rp "}
          onValueChange={(v) => setTopupAmount(v.floatValue)}
        />
        {errorModal && <small className="text-danger">{errorModal}</small>}
      </Modal>
    </>
  );
};

export default UserItem;
