import React from "react";
import { Auth } from "aws-amplify";
import Icon from "./cashless.svg";
import Cognito from "../../assets/img/cognito.png";
import ApiGateway from "../../assets/img/api-gateway.png";
import Lambda from "../../assets/img/lambda.png";
import FlatIcon from "../../assets/img/flaticon.png";
import ReactIcon from "../../assets/img/react.png";
import RDS from "../../assets/img/rds.png";
import KelolaDana from "../../assets/img/keloladana.png";
import ZCode from "../../assets/img/zcode.png";
import { IoIosPulse } from "react-icons/io";
import Pricing from "../account/Pricing";
import { useConfig } from "../../api";

const Landing = (props) => {
  const { data: config, error } = useConfig();
  console.log(config, error);
  const login = (e) => {
    e.preventDefault();
    Auth.federatedSignIn();
  };
  return (
    <div style={{ padding: "10px" }}>
      <hr />
      <center>
        <strong style={{ fontSize: "40px", display: "block" }}>cashflow</strong>
        <button onClick={login} className="btn btn-large btn-outline-primary">
          <IoIosPulse /> Mulai Gunakan
        </button>
      </center>
      <hr />
      <h5>fitur</h5>
      <div className="z-card d-flex mb-2">
        <img src={Icon} className="mr-2" alt="App Icon" width="50px" />
        <p>
          Kelola data keuangan Anda dan simpan di awan. Anda dapat mengakses
          dari device manapun.{" "}
          <a href="" onClick={login} className="d-block">
            Mulai Gunakan
          </a>
        </p>
      </div>
      <div className="z-card d-flex mb-2">
        <img
          src={KelolaDana}
          className="mr-2"
          alt="App Icon"
          width="50px"
          height="50px"
        />
        <p>
          Kelompokan keuangan Anda dalam dompet digital, agar dana Anda tidak
          tercampur.
          <a href="" onClick={login} className="d-block">
            Mulai Gunakan
          </a>
        </p>
      </div>
      <h5>Pricing</h5>
      <Pricing config={config} />
      <h5>technology stack</h5>
      <div className="z-card mb-5 d-flex justify-content-between align-items-center">
        <img src={ReactIcon} alt="aws cognito" width="30px" />
        <img src={ApiGateway} alt="aws cognito" width="30px" />
        <img src={Lambda} alt="aws cognito" width="30px" />
        <img src={RDS} alt="aws cognito" width="30px" />
        <img src={Cognito} alt="aws cognito" width="30px" />
        <img src={FlatIcon} alt="aws cognito" width="30px" />
      </div>
      <center>
        <img src={ZCode} alt="aws cognito" width="30px" />
        <p>
          <small>produksi zcode 2020</small>
        </p>
      </center>
    </div>
  );
};

export default Landing;
