import React from "react";
import {
  IoIosAlarm,
  IoIosArrowDropdown,
  IoIosArrowDropright,
} from "react-icons/io";
import { FormatDate, FormatNumber } from "../../helpers";
import BankItem from "../bank/BankItem";

const BadgeStatus = ({ status, expired }) => {
  let text = "menunggu pembayaran";
  let className = "badge badge-danger";
  if (status == 1) {
    text = "sudah dibayar";
    className = "badge badge-success";
  }
  if (status == 2 || new Date() > expired) {
    text = "expired";
    className = "badge badge-secondary";
  }
  return <span className={className}>{text}</span>;
};

const InvoiceItem = ({ data }) => {
  const [detail, setDetail] = React.useState(false);
  return (
    <div className="z-card mb-2">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <strong style={{ fontSize: "22px" }}>
            Rp {FormatNumber(data.amount)}
          </strong>
          {data.status == 0 ? (
            <small
              className="d-block cursor-pointer"
              onClick={(e) => setDetail(!detail)}
            >
              petunjuk pembayaran&nbsp;
              {detail ? <IoIosArrowDropdown /> : <IoIosArrowDropright />}
            </small>
          ) : null}
        </div>
        <div className="text-right">
          <span className="text-secondary d-block">
            <IoIosAlarm color="red" />{" "}
            {FormatDate(data.expire, "DD MMMM YYYY HH:mm")}
          </span>
          <BadgeStatus status={data} />
        </div>
      </div>
      {detail && (
        <div>
          <hr />
          <p>Silakan transfer ke rekening bank berikut</p>
          <BankItem />
        </div>
      )}
    </div>
  );
};
export default InvoiceItem;
