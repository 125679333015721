import React from "react";
import NavTop from "../../component/NavTop";
import ButtonLoading from "../../component/ButtonLoading";
import NumberFormat from "react-number-format";
import { registerTopUp } from "../../api";
import { FormatNumber } from "../../helpers";

export default (props) => {
  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const nominals = [10000, 20000, 50000, 100000, 200000, 300000, 500000];

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await registerTopUp(amount);
      const url = `/topup/instruction/${data.amount}/${new Date(
        data.expire
      ).getTime()}`;
      props.history.push(url);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavTop title="Top Up" />
      <div className="main-padding">
        <form className="px-2" onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="name">Amount</label>
            <NumberFormat
              id="amount"
              className="form-control"
              thousandSeparator={true}
              prefix={"Rp "}
              value={amount}
              onValueChange={(v) => setAmount(v.floatValue)}
            />
          </div>
          <div className="text-center mb-3">
            {nominals.map((n) => (
              <button
                key={n}
                type="button"
                onClick={(e) => setAmount(n)}
                className="btn btn-outline-success m-1"
              >
                Rp {FormatNumber(n)}
              </button>
            ))}
          </div>
          {loading ? (
            <ButtonLoading />
          ) : (
            <button className="btn btn-block btn-primary">Simpan</button>
          )}
        </form>
      </div>
    </>
  );
};
