import React from "react";
import NavTop from "../../component/NavTop";

const About = () => {
  return (
    <>
     <NavTop title="About" />
     <div className="main-padding">
       Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit recusandae cum aspernatur nobis aperiam totam corporis expedita deserunt, reprehenderit quam voluptas atque. Fugiat, eaque? Eaque eius magni nobis, porro velit rerum, error id, veritatis totam possimus fuga libero! Recusandae explicabo esse qui, corrupti enim nostrum non quibusdam ea minus aut temporibus natus ut cumque dolorum aperiam, perspiciatis fuga ipsa voluptates, alias unde corporis possimus nam quo voluptate! Provident, excepturi magnam? Consequatur recusandae aut consequuntur assumenda quasi, tempora dicta magnam error consectetur itaque in, dolor velit maxime ducimus, facere dolorem qui saepe. Ad odio atque eligendi qui illo? Atque, expedita dolorem?
     </div> 
    </>
  )
}

export default About
