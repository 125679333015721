import React from "react";
import Fab from "../../component/FAB";
import { IoMdAdd } from "react-icons/io";
import Empty from "../../component/Empty";
import { useWallet } from "../../api";
import WalletLoader from "../../loader/WalletLoader";
import WalletCardEdit from "./WalletCardEdit";
import WalletCardSelect from "./WalletCardSelect";

export default (props) => {
  const { wallets, isLoading } = useWallet();
  const type = props.type;
  const currentId = props.match.params.current;
  const KEY = props.match.params.key;
  const all = props.match.params.all;
  const allWallet = {
    id: "0",
    name: "All Wallet",
    description: "Semua Wallet",
  };
  const [data, setData] = React.useState([]);
  const edit = (wallet) => {
    localStorage.setItem("wallet", JSON.stringify(wallet));
    props.history.push("/wallet/edit");
  };
  const selectWallet = (wallet) => {
    localStorage.setItem(KEY, JSON.stringify(wallet));
    window.history.back();
  };
  const searchWallet = (e) => {
    const s = e.target.value;
    const result = wallets.filter(
      (w) => w.name.toLowerCase().indexOf(s.toLowerCase()) >= 0
    );
    setData(result);
  };

  const loadData = () => {
    const w = wallets.find(d => d.id === "0");
    if(all === "all" && w === undefined) wallets.unshift(allWallet);
    setData(wallets);
    if(w !== undefined && all === undefined) setData(wallets.filter(w => w.id !== "0"));
  }
  
  React.useEffect(() => {
    if(!isLoading) loadData();
  }, [isLoading]);
  if(isLoading) return <WalletLoader />;
  if (wallets.length === 0) {
    return (
      <>
        <Empty />
        <Fab
          icon={IoMdAdd}
          onClick={(e) => props.history.push("/wallet/add")}
        />
      </>
    );
  }
  return (
    <>
      <div className="main-padding">
        <input
          type="search"
          className="form-control"
          placeholder="search"
          onChange={searchWallet}
        />
        <div className="mt-3 cursor-pointer">
          {data.map((d, i) =>
            type === "edit" ? (
              <WalletCardEdit
                key={i}
                data={d}
                selected={d.id === currentId}
                onClick={(e) => edit(d)}
              />
            ) : (
              <WalletCardSelect
                key={i}
                data={d}
                selected={d.id === currentId}
                onClick={(e) => selectWallet(d)}
              />
            )
          )}
        </div>
      </div>
      <Fab icon={IoMdAdd} onClick={(e) => props.history.push("/wallet/add")} />
    </>
  );
};
