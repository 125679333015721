import React from "react";
import InvoiceIcon from "../../assets/img/invoice.svg";
import { FormatDate, FormatNumber } from "../../helpers";
import { Link } from "react-router-dom";
import BankCard from "../bank/BankCard";
import { IoIosAlarm } from "react-icons/io";

const rekening = "1320012219433";
const TopUpInstruction = (props) => {
  const { amount, expire } = props.match.params;
  return (
    <div className="p-5">
      <center>
        <img className="d-block" src={InvoiceIcon} alt="topup" width="70px" />
        <span className="d-block mt-3">
          Kami telah menerima permintaan top up dari Anda. Silakan transfer
          sebesar:
        </span>
        <span className="text-big">Rp {FormatNumber(amount)}</span>
        <span className="d-block my-1">sebelum jam</span>
        <span className="d-block font-weight-bold text-danger mb-3">
          <IoIosAlarm /> {FormatDate(new Date(expire * 1), "HH:mm")}
        </span>
      </center>
      <BankCard />
      <hr />
      <Link to="/invoices" className="btn btn-block btn-outline-primary">
        Invoices
      </Link>
      <Link to="/" className="btn btn-block btn-outline-primary">
        Home
      </Link>
    </div>
  );
};

export default TopUpInstruction;
