import axiosInstance from "./axiosInstance";
import useSWR from "swr";

// directly top up, admin only
export async function topUp(amount, subscriber) {
  return await axiosInstance.post("/topup", { amount, subscriber });
}

// kandidate top up
export async function registerTopUp(amount) {
  return await axiosInstance.put("/topup", { amount });
}

export function useTopUp() {
  const { data, error } = useSWR("/topup?size=10");
  return {
    topup: data?.content,
    isLoading: data === undefined,
  };
}
