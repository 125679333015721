import React from "react";
import Skeleton from "@yisheng90/react-loading";

export default (props) => {
  const dummies = [...Array(5).keys()];
  return dummies.map((d, i) => <Card key={i} />);
};

const Card = () => {
  return (
    <div className="z-card mx-1 mb-2">
      <div className="d-flex justify-content-between">
        <Skeleton width={100} height={10} /> 
        <Skeleton width={90} height={10} /> 
      </div>
      <hr />
      <div className={`my-2 text-center`}>
        <span className="d-block">
          <Skeleton width={200} height={20} className="mx-auto" /> 
        </span>
        <Skeleton width={100} height={5} className="mx-auto" /> 
      </div>
      <hr />
      <div className="d-flex justify-content-end">
        <Skeleton width={50} className="mr-2" /> 
        <Skeleton width={50} /> 
      </div>
    </div>
  );
};
