import axiosInstance from "./axiosInstance";
import useSWR from "swr";

export async function initCompany() {
  return await axiosInstance.post("/company");
}

export const useCompany = () => {
  return useSWR("/company");
};

export async function getCompanies() {
  return await axiosInstance.get("/company?all=true");
}

export async function subscribe() {
  return await axiosInstance.put("/subscribe");
}

export const useConfig = () => {
  return useSWR("/config");
};
