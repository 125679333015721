import React from "react";
import Fab from "../../component/FAB";
import Nav from "../../component/Nav";
import Modal from "../../component/Modal";
import {
  IoIosTrash,
  IoMdAdd,
  IoIosWallet,
  IoIosArrowForward,
} from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import {
  FormatDate,
  FormatNumber,
  useLocalStorage,
  expiredStorage,
} from "../../helpers";
import { cashflowGet, cashflowDelete } from "../../api";
import Empty from "../../component/Empty";
import ButtonLoading from "../../component/ButtonLoading";
import TransactionLoader from "../../loader/TransactionLoader";
import { CHALLANGE_TEXT } from "../../constant";

export default (props) => {
  const PAGE_SIZE = 20;
  const walletTrx = JSON.parse(localStorage.getItem("walletTrx"));
  let walletId = walletTrx === null ? "0" : walletTrx.id;
  const [data, setData] = useLocalStorage("trx_data", []);
  const [lastPage, setLastPage] = useLocalStorage("trx_lastPage", false);
  const [currentWalletId, setCurrentWalletId] = useLocalStorage(
    "trx_walletId",
    "0"
  );

  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadMore, setLoadMore] = React.useState(false);
  const [deletedTrx, setDeletedTrx] = React.useState(null);
  const [inputChallange, setInputChallange] = React.useState("");
  const [errorChallange, setErrorChallange] = React.useState("");
  const initData = async () => {
    try {
      setLoading(true);
      const response = await cashflowGet(null, PAGE_SIZE, walletId);
      setData(response.data.content);
      setLastPage(response.data.last);
      setCurrentWalletId(walletId || "0");
    } catch (err) {}
    setLoading(false);
  };
  React.useEffect(() => {
    const isExpired = expiredStorage("trx");
    const isWalletChange = currentWalletId !== walletId;
    if (isExpired || isWalletChange || data.length === 0) initData();
  }, []);

  const remove = () => {
    if (inputChallange !== CHALLANGE_TEXT) {
      setErrorChallange("Challange Text tidak sesuai");
      return;
    }
    cashflowDelete(deletedTrx.id);
    setModal(false);
    setData(data.filter((d) => d.id !== deletedTrx.id));
  };
  const closeModal = () => {
    setErrorChallange("");
    setInputChallange("");
    setModal(false);
  };
  const fetchMore = async (e) => {
    e.preventDefault();
    try {
      setLoadMore(true);
      const o = data[data.length - 1];
      const response = await cashflowGet(o.date, PAGE_SIZE, walletId);
      setData([...data, ...response.data.content]);
      setLastPage(response.data.last);
      setLoadMore(false);
    } catch (err) {}
  };

  const selectWallet = () => {
    walletId = walletId || "0";
    props.history.push(`/wallet/search/${walletId}/walletTrx/all`);
  };
  if (loading)
    return (
      <>
        <Nav title="Transaction" />
        <div className="main-padding">
          <TransactionLoader />
        </div>
      </>
    );

  if (data.length === 0) {
    return (
      <>
        <Nav title="Transaction" />
        <div className="main-padding">
          <button
            className="btn btn-secondary btn-block mb-2"
            onClick={selectWallet}
          >
            {walletTrx === null ? "All Wallet" : walletTrx.name}&nbsp;
            <IoIosArrowForward />
          </button>
        </div>
        <Empty />
        <Fab
          icon={IoMdAdd}
          onClick={(e) => {
            localStorage.removeItem("walletSearch");
            props.history.push("/transaction/add");
          }}
        />
      </>
    );
  }

  return (
    <>
      <Nav title="Transaction" />
      <div className="main-padding">
        <button
          className="btn btn-secondary btn-block mb-2"
          onClick={selectWallet}
        >
          {walletTrx === null ? "All Wallet" : walletTrx.name}&nbsp;
          <IoIosArrowForward />
        </button>
        {data.map((d, i) => (
          <Card
            data={d}
            key={i}
            {...props}
            onDelete={(trx) => {
              setModal(true);
              setDeletedTrx(trx);
            }}
          />
        ))}
        {loadMore ? (
          <ButtonLoading />
        ) : (
          !lastPage && (
            <button
              className="btn btn-outline-primary btn-block my-4"
              onClick={fetchMore}
            >
              Load More
            </button>
          )
        )}
        <Fab
          icon={IoMdAdd}
          onClick={(e) => {
            localStorage.removeItem("walletSearch");
            props.history.push("/transaction/add");
          }}
        />
        <Modal
          show={modal}
          title="Delete Transaction"
          onClose={() => setModal(false)}
          onPositive={remove}
          onNegative={closeModal}
        >
          Proses ini tidak dapat di-undo. Jika Anda yakin, silakan tulis&nbsp;
          <strong className="text-danger">{CHALLANGE_TEXT}</strong> di bawah
          ini.
          <input
            type="text"
            className="form-control mt-1"
            value={inputChallange}
            onChange={(e) => setInputChallange(e.target.value)}
          />
          {errorChallange.length > 0 && (
            <small className="text-danger">{errorChallange}</small>
          )}
        </Modal>
      </div>
    </>
  );
};

const Card = (props) => {
  const { data } = props;
  const edit = () => {
    localStorage.setItem("cashflow", JSON.stringify(data));
    props.history.push("/transaction/edit/" + data.id);
  };
  const color = data.amount < 0 ? "text-danger" : "";
  const searchByTag = (tag) => {
    localStorage.setItem("tag", JSON.stringify(tag));
    props.history.push("/transaction-search");
  };
  return (
    <div className="z-card mx-1 mb-2">
      <div className="d-flex justify-content-between">
        <strong>
          <IoIosWallet /> {data.wallet.name}
        </strong>
        <span className="text-secondary">{FormatDate(data.date)}</span>
      </div>
      <hr />
      <div className={`my-2 text-center ${color}`}>
        <span className="d-block">
          <small>Rp</small>
          <span className={`font-weight-bold`} style={{ fontSize: "2rem" }}>
            {FormatNumber(data.amount)}
          </span>
        </span>
        <footer className="blockquote-footer mb-1">{data.description}</footer>
        <div>
          {data.tags.map((t, i) => (
            <small
              key={i}
              onClick={() => searchByTag(t)}
              className="badge badge-secondary cursor-pointer mr-1"
            >
              {t.tag}
            </small>
          ))}
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-end">
        <div className="mr-3 z-link" onClick={edit}>
          <FiEdit2 className="mr-1" />
          Edit
        </div>
        <div className="z-link" onClick={() => props.onDelete(data)}>
          <IoIosTrash className="mr-1" />
          Delete
        </div>
      </div>
    </div>
  );
};
