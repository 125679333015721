import React from "react";
import NavTop from "../../component/NavTop";
import ButtonLoading from "../../component/ButtonLoading";
import { AuthContext } from "../../auth/ZAuth";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

export default (props) => {
  const { changePassword } = React.useContext(AuthContext);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState(0);
  const [newPasswordRe, setNewPasswordRe] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const confirm = window.confirm(
      "Setelah Anda mengubah password, Anda akan keluar aplikasi dan login menggunakan password baru"
    );
    if (!confirm) return;
    if (newPassword !== newPasswordRe) {
      toast.error("Password baru tidak sama");
      return;
    }
    try {
      setLoading(true);
      const u = await Auth.currentAuthenticatedUser();
      await u.changePassword(oldPassword, newPassword);
      Auth.signOut();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavTop title="Change Password" />
      <div className="main-padding">
        <form className="px-2" onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="name">Old Password</label>
            <input
              type="password"
              id="oldPassword"
              className="form-control"
              onChange={(e) => setOldPassword(e.target.value)}
              aria-describedby="amountName"
            />
          </div>
          <div className="form-group">
            <label htmlFor="amount">New Password</label>
            <input
              type="password"
              id="newPassword"
              className="form-control"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="amount">New Password (ulangi)</label>
            <input
              type="password"
              id="newPasswordRe"
              className="form-control"
              onChange={(e) => setNewPasswordRe(e.target.value)}
            />
          </div>
          {loading ? (
            <ButtonLoading />
          ) : (
            <button className="btn btn-block btn-primary">Simpan</button>
          )}
        </form>
      </div>
    </>
  );
};
