import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/style.scss";
import { SWRConfig } from "swr";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "./routes";
import { AuthContext, ZAuth } from "./auth/ZAuth";
import Landing from "./pages/landing/Landing";
import axiosInstance from "./api/axiosInstance";

const fetcher = (u) => axiosInstance.get(u).then((r) => r.data);

const Main = (props) => {
  const { user } = React.useContext(AuthContext);
  if (user === null) return <div />;
  if (user === undefined) return <Redirect to="/landing" />;
  return (
    <>
      {routes.map((r, i) => (
        <Route key={i} path={r.to} exact={r.exact} component={r.component} />
      ))}
    </>
  );
};

function App() {
  return (
    <SWRConfig value={{ fetcher, revalidateOnFocus: false }}>
      <Router>
        <ZAuth>
          <Switch>
            <Route path="/landing" exact component={Landing} />
            <Route path="/" component={Main} />
            <Redirect to="/landing" />
          </Switch>
        </ZAuth>
        <ToastContainer />
      </Router>
    </SWRConfig>
  );
}

export default App;
