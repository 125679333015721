import React from "react";
import Skeleton from "@yisheng90/react-loading";

const AccountLoader = () => {
  return (
    <div className="main-padding">
      <div className="d-flex-column z-card mb-2">
        <Skeleton width={200} />
        <Skeleton width={250} />
        <Skeleton width={125} />
      </div>
      <div className="d-flex z-card mb-2">
        <Skeleton width={100} height={30} className="d-block mx-auto" />
      </div>
      <div className="list-menu mt-4">
        {[2,3,4,3,5,4,3,2].map((i, k) => (
          <span key={k}>
            <Skeleton width={150 + 100 / i } height={20} />
            <hr/>
          </span>
        ))}
      </div>
    </div>
  )
}

export default AccountLoader
