import React from "react";
import {
  IoIosTrash,
  IoMdAdd,
  IoIosWallet,
  IoMdFiling,
  IoIosArrowForward,
} from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import { FormatDate, FormatNumber } from "../../helpers";

const typeName = (type) => {
  switch (type) {
    case 1:
      return "Top Up";
    case 2:
      return "Pembayaran";
    case 3:
      return "Gift";
    default:
      return "-";
  }
};

const Card = (props) => {
  const { data } = props;
  const color = data.amount < 0 ? "text-danger" : "";
  return (
    <div className="z-card mx-1 mb-2">
      <div className="d-flex justify-content-between">
        <strong>
          <IoMdFiling /> {typeName(data.type)}
        </strong>
        <span className="text-secondary">{FormatDate(data.date)}</span>
      </div>
      <hr />
      <div className={`my-2 text-center ${color}`}>
        <span className="d-block">
          <span className={`font-weight-bold`} style={{ fontSize: "2rem" }}>
            {FormatNumber(data.amount)}
          </span>
        </span>
        <footer className="blockquote-footer mb-1">{data.notes}</footer>
      </div>
    </div>
  );
};

export default Card;
